import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'img[fallback]',
  host: {
    '(error)': 'handleImageError()',
    '(load)': 'handleImageLoad()',
    '[src]': 'src'
  }
})
export class ImagePreloadDirective {
  @Input() src: string;
  @Input() fallback: string;

  @HostBinding('attr.data-loaded') loadStatus = 'loading';

  handleImageError() {
    this.src = this.fallback;
    this.loadStatus = 'failed';
  }

  handleImageLoad() {
    this.loadStatus = 'success';
  }
}
