import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { LoadingOverlayComponent } from './components/loading-overlay/loading-overlay.component';
import { TitleBadgeComponent } from './components/title-badge/title-badge.component';
import { UploadMediaInputComponent } from './components/upload-media-input/upload-media-input.component';
import { DragDropFileUploadDirective } from './directives/drag-drop-file-upload.directive';
import { IfLoadedDirective } from './directives/if-loaded.directive';
import { ImagePreloadDirective } from './directives/image-preload.directive';
import { OverlayDirective } from './directives/overlay.directive';
import { BackgroundColorPipe } from './pipes/background-color.pipe';
import { ColorPipe } from './pipes/color.pipe';
import { MarkdownPipe } from './pipes/markdown.pipe';
import { ObsWithStatusPipe } from './pipes/obs-with-status.pipe';
import { SafeTransformPipe } from './pipes/safe-tranform.pipe';
import { SecureMediaPipe } from './pipes/secure-media.pipe';
import { SubstringPipe } from './pipes/substring.pipe';

@NgModule({
  declarations: [
    OverlayDirective,
    ImagePreloadDirective,
    MarkdownPipe,
    SafeTransformPipe,
    IfLoadedDirective,
    DragDropFileUploadDirective,
    UploadMediaInputComponent,
    LoadingOverlayComponent,
    TitleBadgeComponent,
    SubstringPipe,
    SecureMediaPipe,
    ObsWithStatusPipe,
    BackgroundColorPipe,
    ColorPipe
  ],
  imports: [CommonModule, AngularSvgIconModule],
  exports: [
    OverlayDirective,
    ImagePreloadDirective,
    SafeTransformPipe,
    SubstringPipe,
    SecureMediaPipe,
    ObsWithStatusPipe,
    MarkdownPipe,
    IfLoadedDirective,
    DragDropFileUploadDirective,
    UploadMediaInputComponent,
    TitleBadgeComponent,
    LoadingOverlayComponent,
    AngularSvgIconModule,
    BackgroundColorPipe,
    ColorPipe
  ],
  providers: []
})
export class SharedModule {}
