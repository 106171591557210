import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ImageAuth } from 'src/app/shell/models/auth.model';
import {
  Content,
  ExternalLink
} from 'src/app/shell/models/portfolio-book.model';

import { ApiGatewayService, IRequestOptions } from './api/api-gateway.service';
import { LivPortfolioResponseProtocol } from '../models/liv-portfolio-response-protocol.model';
import { StrapiUserSessionModel } from '../models/strapi-user-session.model';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  constructor(
    private httpClient: HttpClient,
    private portfolioApi: ApiGatewayService
  ) {}

  getExternalLink(
    bookId: number
  ): Observable<LivPortfolioResponseProtocol<ExternalLink>> {
    return this.portfolioApi.get<ExternalLink>(`/livros/linkExterno/${bookId}`);
  }

  getContent(slug: string): Observable<LivPortfolioResponseProtocol<Content>> {
    return this.portfolioApi.get<Content>(`/content/${slug}`);
  }

  getAuthToken(
    token: string
  ): Observable<LivPortfolioResponseProtocol<StrapiUserSessionModel>> {
    const headers = new HttpHeaders({ 'token-api-liv': token });
    return this.portfolioApi.get<StrapiUserSessionModel>('/auth/local', {
      headers
    });
  }

  getImageToken(): Observable<LivPortfolioResponseProtocol<ImageAuth>> {
    return this.portfolioApi.get<ImageAuth>('/auth/image');
  }

  downloadExternal(
    endpoint: string,
    options: IRequestOptions = {}
  ): Observable<Blob> {
    const url = encodeURI(endpoint);
    return this.httpClient.get<Blob>(url, {
      ...options,
      responseType: 'blob' as 'json'
    });
  }
}
