import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

const googleUrl = new RegExp(
  'https://storage.googleapis.com/liv-portifolio-bucket(-dev)?(-hml)?/'
);
@Pipe({
  name: 'secureMedia'
})
export class SecureMediaPipe implements PipeTransform {
  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {}

  transform(url: string): Observable<string> {
    const apiUrl = environment.apiLiv + '/url/file';
    const path = url.replace(googleUrl, '');

    const newUrl = `${apiUrl}?queryPath=${path}`;
    return this.http.get(newUrl, { responseType: 'blob' }).pipe(
      map((val) => {
        if (val.type === 'application/json') {
          return undefined;
        }
        return this.sanitizer.sanitize(
          SecurityContext.RESOURCE_URL,
          this.sanitizer.bypassSecurityTrustResourceUrl(
            URL.createObjectURL(val)
          )
        );
      })
    );
  }
}
